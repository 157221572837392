var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("SearchForm", {
              ref: "searchForm",
              staticStyle: { padding: "0" },
              attrs: {
                "expand-limit": 7,
                "form-list-extend": _vm.searchFormList,
                "form-data": _vm.searchFormData,
                "default-form": _vm.defaultForm
              },
              on: {
                "update:formData": function(data) {
                  return (_vm.searchFormData = data)
                },
                search: _vm.reSearch
              }
            })
          ],
          1
        ),
        _c("FunctionHeader", {
          ref: "functionHeader",
          attrs: {
            "search-title": _vm.mode
              ? "请输入变更单号/订单编号/名称"
              : "请输入变更单号/项目编号/名称",
            tags: _vm.functionHeader.tags,
            "export-name": _vm.functionHeader.exportName,
            "export-params": _vm.functionHeader.exportParams
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.getColumns,
            height: _vm.height,
            "set-data-method": _vm.getTableData,
            "columns-key": _vm.columnsKey
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "prePlanChangeNumber",
              fn: function(ref) {
                var row = ref.row
                return _c("NewPageOpen", {
                  attrs: {
                    path: {
                      name: "ChangeManagementDetail",
                      query: { id: row.id, type: "view" }
                    },
                    text: row.prePlanChangeNumber
                  }
                })
              }
            },
            {
              key: "needOperate",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c("span", [
                    _vm._v(_vm._s(row.needOperate ? "不需要" : "需要"))
                  ])
                ])
              }
            },
            {
              key: "changeType",
              fn: function(ref) {
                var row = ref.row
                return [
                  !row.isEdit
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.changeType[row.changeType].name))
                      ])
                    : _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: row.changeType,
                            callback: function($$v) {
                              _vm.$set(row, "changeType", $$v)
                            },
                            expression: "row.changeType"
                          }
                        },
                        _vm._l(_vm.changeType, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                ]
              }
            },
            {
              key: "changeStatus",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.changeStatus[row.status].name))
                  ])
                ])
              }
            },
            {
              key: "action",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _vm.permission("Detail")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(row.id)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e(),
                    _vm.permission("Detail")
                      ? _c("el-divider", { attrs: { direction: "vertical" } })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !row.isEdit,
                            expression: "!row.isEdit"
                          }
                        ]
                      },
                      [
                        _vm.permission("Modify")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    row.status === 3 ||
                                    row.status === 4 ||
                                    row.status === 5,
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(row, "isEdit", true)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.isEdit,
                            expression: "row.isEdit"
                          }
                        ]
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.$set(row, "isEdit", false)
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onSave(row)
                              }
                            }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.permission("Modify")
                      ? _c("el-divider", { attrs: { direction: "vertical" } })
                      : _vm._e(),
                    _vm.permission("Del")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled:
                                row.status === 3 ||
                                row.status === 4 ||
                                row.status === 5
                            },
                            on: {
                              click: function($event) {
                                return _vm.del(row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }