<template>
    <div class="scrap-wrap">
      <div class="bottom">
        <div class="top">
        <SearchForm
          ref="searchForm"
          :expand-limit="7"
          :form-list-extend="searchFormList"
          :form-data="searchFormData"
          style="padding: 0"
          :default-form="defaultForm"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />

      </div>
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          :search-title="mode ? '请输入变更单号/订单编号/名称' : '请输入变更单号/项目编号/名称' "
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData" :columns-key="columnsKey">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="prePlanChangeNumber" slot-scope="{ row }" :path="{name:'ChangeManagementDetail',query:{id:row.id,type:'view'}}" :text="row.prePlanChangeNumber" />
          <div slot="needOperate" slot-scope="{ row }" >
            <span>{{ row.needOperate ? '不需要':'需要' }}</span>
          </div>

          <template slot="changeType" slot-scope="{ row }" >
            <div v-if="!row.isEdit">{{ changeType[row.changeType].name  }}</div>
            <el-select v-else v-model="row.changeType" placeholder="请选择">
              <el-option
                v-for="item in changeType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
          <div slot="changeStatus" slot-scope="{ row }" >
            <span>{{ changeStatus[row.status].name }}</span>
          </div>
          <div slot="action" slot-scope="{ row }">
                <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row.id)"
                  >详情</el-button
                >
                <el-divider v-if="permission('Detail')" direction="vertical" />
                <span v-show="!row.isEdit">
                <el-button
                v-if="permission('Modify')"
                :disabled="row.status === 3 || row.status === 4 || row.status === 5"
                type="text"
                @click="$set(row,'isEdit',true)"
              >编辑</el-button>
            </span>        
            <span v-show="row.isEdit">
              <el-button type="text" @click="$set(row,'isEdit',false)">取消</el-button>
              <el-button type="text" @click="onSave(row)">保存</el-button>
            </span>&nbsp;
                
                <el-divider v-if="permission('Modify')" direction="vertical" />
                <el-button
                  v-if="permission('Del')"
                  type="text"
                  @click="del(row.id)"
                  :disabled="row.status === 3 || row.status === 4 || row.status === 5"
                  >删除</el-button
                >
              </div>
        </MTable>
      </div>
    </div>
  </template>

  <script>
  import { columns ,searchFormList} from './columns'
  import api from '@/api/changeManagement/changeManagement'
  import conventionMixin from '@/mixin/conventional-page'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import CURDSelect from '@/components/CURDSelect/CURDSelect'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { changeStatus,changeType } from '@/config/options.config'
  import SearchForm from '@/components/SearchForm/SearchForm'

  export default {
    name: 'ChangeManagementList',
    components: { NewPageOpen, CURDSelect, FunctionHeader,SearchForm },
    mixins: [conventionMixin],
    props:{
      projectId:{
        type: Number,
         default: undefined
      }
    },
    data() {
      return {
        api,
        isProcess: false,
        changeType,
        changeStatus,
        params: {
          radio: 'ChangeWork'
        },
        columns,
        searchFormList,
        searchFormData: {
          status:"",
          needOperate:0
        },
        defaultSearchFormData: {
          procedureId: ''
        },
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
              }
            }
          }
        ],
          exportName: '',
          exportParams: {}
        },
        formData: {},
        currentType: 'add',
        visible: false,
        stationVisible: false,
        stationList: [],
        searchKeyword: true,
        defaultForm: {needOperate:0 },
        isPageLoaded:false
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.projectId ? this.$store.state.app.contentHeight - 235 - 10 + 35 - 46 - 42: this.$store.state.app.contentHeight - 235 - 10 + 35 - 46
      },
      columnsKey() {
      return this.$route.name + this.params.radio
     },
     mode() {
      return this.$store.state.app.mode
    },
      getColumns(){
        if (this.mode === 1) {
        return this.columns.map(item => {
      const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
      return { ...item, label: newLabel }
    })
      } else {
        return this.columns
      }
    }
    },
    watch: {
    },
    created() {
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    beforeMount() {
  },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
        async getTableData(condition, callback) {
        const { page, search, order } = condition

        // const data = Object.assign({}, ...this.searchFormatData)
        const {...data } = this.searchFormData

        console.log(data);
        const searchCondition = {
            searchVal: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          projectId:this.projectId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      add(){
         this.$router.push({ name: 'AddChangeOrder'})
      },   
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        this.$refs.mTable.setTableData(true)
      },
      // 执行
      async onExecute(id) {
        const res = await api.executeChangeWork(id)
        if (res) {
          this.$message.success('执行成功')
          this.$refs.mTable.setTableData()
        }
    },
    viewDetail(id){
        this.$router.push({ name: 'ChangeManagementDetail',query:{id:id,type:'view'}})
      },
      async onSave(row) {
        const res = await api.updateChangeWork({id:row.id,changeType:row.changeType})
        if (res) {
          this.$message.success('修改成功')
          this.$refs.mTable.setTableData(true)
          this.$set(row, 'isEdit', false)
          delete row.isEdit
        }
    },
    async del(id){
      this.$confirm('您确定要删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            const res = await api.delChangeWork({id})
            if (res) {
              this.$message.success('删除成功')
              await this.$refs.mTable.setTableData()
            }
          })
    }
    }
}
  </script>

  <style scoped lang="scss">
  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  .role-tags {
    margin: 5px;
    border-color: #dde3eb;
    color: #9597ae;
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item__content {
      height:32px;
    }
  }
  </style>
